import React, {useRef, useState} from 'react';
import {useAppDispatch, verifyPhone, verifyPhoneCode} from "../../app/store";
import {Box, Paper, TextField, Typography, Button, Alert} from "@mui/material";
import {Logo} from 'images/Logo';
import {Link} from "../../components";
import {navigate} from "gatsby";
import {getAuth, RecaptchaVerifier} from "firebase/auth";
import { verifiedUser } from '../../app/store/thunks/authThunks';

export default function VerifyPhone() {
  const dispatch = useAppDispatch();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [verificationId, setVerificationId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let appVerifier: RecaptchaVerifier | null = null;
  const recaptchaWrapperRef = useRef<HTMLDivElement>(null); // Ref for the recaptcha container
  const [recaptchaId, setRecaptchaId] = useState<string>('recaptcha-container'); // Unique ID state
  const generateUniqueRecaptchaId = () => `recaptcha-container-${Date.now()}`;


  const generateRecaptcha = (recaptchaIdParam: string) => {
    const auth = getAuth();
    console.log('newRecaptchaId:', recaptchaIdParam);
    appVerifier = new RecaptchaVerifier(
      auth, recaptchaIdParam,
      {
        size: "invisible",
      }
    );
  };

  const recreateRecaptchaContainer = () => {
    if (recaptchaWrapperRef.current) {
      recaptchaWrapperRef.current.innerHTML = '';
    }
    const newRecaptchaId = generateUniqueRecaptchaId();
    setRecaptchaId(newRecaptchaId);
    const newContainer = document.createElement('div');
    newContainer.id = newRecaptchaId;
    if (recaptchaWrapperRef.current) {
      recaptchaWrapperRef.current.appendChild(newContainer);
    }
    return newRecaptchaId;
  };

  const handleSendCode = async () => {
    if (!phoneNumber) {
      setError('Please enter a valid phone number.');
      return;
    }
    let formattedPhoneNumber = phoneNumber.trim();
    if (formattedPhoneNumber.startsWith('0')) {
      formattedPhoneNumber = '+61' + formattedPhoneNumber.substring(1);
    } else if (!formattedPhoneNumber.startsWith('+61')) {
      setError('Please enter a valid Australian phone number starting with +61 or 0.');
      return;
    }
    setError(null);
    setIsLoading(true);
    try {
      // Re-create the reCAPTCHA container
      const newRecaptchaId = recreateRecaptchaContainer();
      generateRecaptcha(newRecaptchaId);
      if (appVerifier) {
        const result = await dispatch(verifyPhone({phoneNumber: formattedPhoneNumber, appVerifier})).unwrap();
        setVerificationId(result.verificationId);
        setIsCodeSent(true);
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyCode = async () => {
    if (!verificationCode || !verificationId) {
      setError('Please enter the verification code.');
      return;
    }
    setError(null);
    setIsLoading(true);
    try {
      await dispatch(verifyPhoneCode({verificationCode, verificationId})).unwrap();
      await verifiedUser();
      navigate('/dashboard', {replace: true});
    } catch (err: any) {
      console.log(err.message);
      setError(err.message)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      component="article"
      sx={{
        pt: 12,
        width: '100vw',
        height: '100vh',
        overflow: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        bgcolor: 'background.default',
      }}>
      <Logo/>

      <Paper sx={{my: 4, px: 4, py: 6, width: {xs: '90%', sm: 500}}}>
        <Box sx={{textAlign: 'center', mb: 3}}>
          <Typography variant="h5">PHONE VERIFICATION</Typography>
        </Box>

        {error && <Alert severity="error">{error}</Alert>}

        {!isCodeSent ? (
          <>
            <Typography paragraph>
              Please enter your mobile phone number including the country code +61 (e.g. +61402556988) to receive a
              verification code.
            </Typography>
            <TextField
              fullWidth
              label="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              sx={{mb: 3}}
            />
            <Box sx={{textAlign: 'center'}}>
              <Button onClick={handleSendCode} variant="contained" disabled={isLoading}>
                {isLoading ? 'Sending Code...' : 'Send Verification Code'}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography paragraph>
              Please enter the verification code sent to your phone.
            </Typography>
            <TextField
              fullWidth
              label="Verification Code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              sx={{mb: 3}}
            />
            <Box sx={{textAlign: 'center'}}>
              <Button onClick={handleVerifyCode} variant="contained" disabled={isLoading}>
                {isLoading ? 'Verifying Code...' : 'Verify Code'}
              </Button>
            </Box>
          </>
        )}

        <Box sx={{textAlign: 'center', mt: 2}}>
          <Typography marginBottom={4}>
            Go back to the <Link href="/">Home page</Link>
          </Typography>
        </Box>
      </Paper>

      <div ref={recaptchaWrapperRef}>
        <div id={recaptchaId}></div>
      </div>
    </Box>
  );
}
